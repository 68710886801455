































































































































import accountStateRef from "@/apps/accounts/modules/store";
import router from "@/router";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SideBar",
  components: {
    NavBar: () => import("../components/NavBar.vue"),
  },
  setup() {
    const logout = () => {
      accountStateRef.me.logout();
      router.push({ name: "login" });
    };

    return {
      // Method
      logout,
    };
  },
});
